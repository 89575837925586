import React from "react"
import {graphql} from "gatsby"
import {FormattedMessage, injectIntl, Link, useIntl} from "gatsby-plugin-react-intl"
import Parser from "html-react-parser"

import Layout from "../components/layout"

const QuarksPage = ({data}) => {
    const intl = useIntl()
    const lang = intl.locale
    var pubs = ""
    if (lang === "ru") {
        pubs = data.ru_publications.edges;
    } else if (lang === "en") {
        pubs = data.en_publications.edges;
    }
    return (
        <Layout>
            <nav aria-label="breadcrumb">
                <ol className="breadcrumb" style={{margin: 0}}>
                    <li className="breadcrumb-item">
                        <Link to={`/projects/physics`}><FormattedMessage id="quarks"/></Link>
                    </li>
                    <li className="breadcrumb-item active">QUARKS-2020</li>
                </ol>
            </nav>

            {pubs.map(({node}) => {
                return (
                    <div>{Parser(node.html)}</div>
                )
            })}
        </Layout>
    )
}

export default injectIntl(QuarksPage)

export const query = graphql`
    query{
        ru_publications: allMarkdownRemark(filter: {frontmatter: {shortTitle: {eq: "QUARKS-2020"}, published: {ne: false}, language: {eq: "ru"}}}){
            edges{
                node{
                    html
                    frontmatter{
                        title
                    }
                }
            }}

        en_publications: allMarkdownRemark(filter: {frontmatter: {shortTitle: {eq: "QUARKS-2020"}, published: {ne: false}, language: {eq: "en"}}}){
            edges{
                node{
                    html
                    frontmatter{
                        title
                    }
                }
            }}
    }
`